<template>
  <div class="consume-manage">
    <!-- 大开关 -->
    <div class="enable-box">
      <el-switch
        v-model="featureInfo.Useable"
        :active-value="true"
        :inactive-value="false"
      >
      </el-switch>
      <i class="el-icon-warning-outline"></i>
      <span>对设置好消费周期的顾客，可定期自动发送通知，提醒到店消费</span>
    </div>

    <template v-if="!featureInfo.Useable">
      <el-form>
        <el-form-item class="start-img df-col" label-width="80px">
          <div>功能描述：开启后，自动对具有消费周期管理的挽回营销</div>
          <el-image :src="imgUrl" fit="contain"></el-image>
        </el-form-item>
      </el-form>
    </template>

    <template v-else>
      <el-form ref="form" label-width="80px">
        <!-- 生成待办 -->
        <el-form-item class="strong" label="生成待办">
          <i class="el-icon-warning-outline"></i>
          <span>提醒员工或管理员回访消费周期到了的顾客，进行预约到店</span>
        </el-form-item>
      </el-form>

      <div>
        <!-- 提醒到谁 -->
        <Remind
          ref="Remind"
          :dataInfo="{ employee: remindEmployee, manager: remindManager }"
          @change="onChangeRemind"
        ></Remind>

        <!-- 分割线 -->
        <el-divider></el-divider>
      </div>

      <div>
        <el-form ref="form" label-width="80px">
          <!-- 提醒顾客 -->
          <el-form-item class="strong" label="提醒顾客">
            <el-switch
              v-model="remindMember.Useable"
              :active-value="true"
              :inactive-value="false"
              @change=""
            >
            </el-switch>
            <i class="el-icon-warning-outline"></i>
            <span>提醒员工或管理员回访消费周期到了的顾客，进行预约到店</span>
          </el-form-item>

          <!-- 未开启提醒 -->
          <el-form-item v-if="!remindMember.Useable" class="df-col">
            <span>开启后，顾客消费周期到了时，将短信提醒顾客</span>
            <el-image :src="imgUrl" fit="contain"></el-image>
          </el-form-item>

          <template v-else>
            <!-- 发送时间 -->
            <el-form-item label="发送时间">
              <div class="select-time-box">
                <el-time-picker
                  v-model="sendDate"
                  size="small"
                  :picker-options="{
                    selectableRange: '00:00:00 - 23:59:59',
                    format: 'HH:mm',
                  }"
                  placeholder="选择时间点"
                  @change="onChangeDate"
                >
                </el-time-picker>
              </div>
            </el-form-item>

            <!-- 发送类型 -->
            <el-form-item label="发送类型">
              <div class="template-box">
                <el-radio-group
                  v-model="remindMember.WxOrSms"
                  @change="onChangeRadio"
                >
                  <el-radio :label="1">微信</el-radio>
                  <el-radio :label="2">短信</el-radio>
                </el-radio-group>
              </div>
            </el-form-item>

            <!-- 发送内容 -->
            <el-form-item label="发送内容">
              <el-button size="small" type="primary" @click="onShowTemplate"
                >选择模板</el-button
              >

              <div class="notice-box">
                <el-input
                  id="Textarea"
                  ref="Textarea"
                  v-model="notice"
                  placeholder="您可以选择短信模板或在此输入短信通知内容"
                  type="textarea"
                  :rows="5"
                  maxlength="192"
                  show-word-limit
                  @blur="onSaveContent"
                ></el-input>

                <div class="text-insert">
                  <span class="title"
                    >内容中包含以下内容可点击蓝色文字快速插入，发送时会以实际内容代替</span
                  >
                  <div v-for="(item, index) in templateSymbolList" :key="index">
                    <span @click="onInsetSymbol(item)">{{ item.Value }}</span>
                    {{ item.Desc }}
                  </div>
                </div>
              </div>

              <!-- 保存模板 -->
              <el-checkbox v-model="saveAs" @change="onChangeSaveAs"
                >保存为【消费周期】类型的
                <span :class="remindMember.WxOrSms == 1 ? 'wx' : 'msg'">{{
                  remindMember.WxOrSms == 1 ? "微信" : "短信"
                }}</span>
                模板</el-checkbox
              >
            </el-form-item>
          </template>
        </el-form>

        <!-- 分割线 -->
        <el-divider></el-divider>
      </div>

      <div>
        <el-button type="primary" size="small" @click="saveFeature"
          >保存</el-button
        >
      </div>
    </template>

    <DfSmsTemplate
      ref="DfSmsTemplate"
      :code="code"
      @confirm="onChangeTemplate"
    ></DfSmsTemplate>
  </div>
</template>

<script>
import Marketing from "@/api/marketing.js";
import Template from "@/api/template.js";

import Remind from "../components/remind.vue";

let img = require("@/assets/img/no-pictrue-rectangle.png");
export default {
  components: { Remind },

  data() {
    return {
      code: "ExpeCycle", // 功能code
      sendDate: "", // 发送时间
      imgUrl: img, // 默认图片
      notice: "", // 短信文本
      cursorPosition: 0, // 光标位置
      featureInfo: {}, // 功能信息
      remindEmployee: {}, // 门店员工信息
      remindManager: {}, // 门店管理员信息
      remindMember: {
        Useable: false,
      }, // 提醒顾客信息
      selectTemItem: {}, // 选中的短信模板信息
      templateSymbolList: [], // 模板符号列表
      remindSwitch: false, // 提醒顾客开关
      saveAs: false, // 保存为短信模板选项框
    };
  },

  async mounted() {
    let date = new Date(),
      year = date.getFullYear(),
      month = date.getMonth() + 1,
      day = date.getDate();

    // this.saveAs = window.localStorage.getItem(`${this.code}TempSaveAs`)
    await this.getFeatureData();
    await this.getTemplateSymbols();
    this.sendDate = await new Date(
      year,
      month,
      day,
      this.remindMember.SendHour || 0,
      this.remindMember.SendMinute || 0,
      0
    );
  },

  methods: {
    // 获取功能数据
    async getFeatureData() {
      try {
        let { data } = await Marketing.getFeatureData({
          code: this.code,
        });
        let { RemindEmployee, RemindManager, RemindMember } = data;

        this.remindEmployee = RemindEmployee;
        this.remindManager = RemindManager;
        this.remindMember = RemindMember;
        this.featureInfo = {
          Useable: data.Useable,
        };

        this.onChangeRadio();
      } catch (err) {
        console.log(err);
      }
    },

    // 获取模板符号
    async getTemplateSymbols() {
      try {
        let { data } = await Template.getTemplateSymbols({
          class_code: this.code,
        });
        this.templateSymbolList = data;
      } catch (err) {
        console.log(err);
      }
    },

    // 添加消息模板
    async addTemplate() {
      let { remindMember, code, notice } = this;
      try {
        let submitData = {
          ClassCode: code,
          ApplyType: remindMember.WxOrSms,
          TplContent: notice,
        };
        let { errcode } = await Template.addTemplate(submitData);
        if (errcode == 0) {
          this.$message.success("添加成功!");
          this.getFeatureData();
        }
      } catch (err) {
        console.log(err);
      }
    },

    // 保存功能数据
    async saveFeature() {
      let {
        remindEmployee,
        remindManager,
        remindMember,
        featureInfo,
        code,
      } = this;

      try {
        let submitData = {
          Code: code,
          Data: {
            ...featureInfo,
            RemindEmployee: remindEmployee,
            RemindManager: remindManager,
            RemindMember: remindMember,
          },
        };
        let { errcode } = await Marketing.saveFeature(submitData);
        if (errcode == 0) {
          this.$message.success("设置保存成功!");
          if (!this.saveAs) {
            return this.getFeatureData();
          }
          setTimeout(() => {
            this.addTemplate();
          }, 1000);
        }
      } catch (err) {
        console.log(err);
      }
    },

    // 符号插入事件
    onInsetSymbol(event) {
      let dom = document.getElementById("Textarea");
      // 检查浏览器是否支持 selectionRange 属性
      if (typeof dom.selectionStart === "number") {
        this.cursorPosition = dom.selectionStart;
      } else if (document.selection) {
        // 这段代码适用于旧版本的 Internet Explorer
        dom.focus();
        let selection = document.selection.createRange();
        let selectionLength = document.selection.createRange().text.length;
        selection.moveStart("character", -dom.value.length);
        this.cursorPosition = selection.text.length - selectionLength;
      } else {
        this.cursorPosition = 0;
      }

      this.$nextTick(() => {
        let { notice } = this;
        this.notice =
          notice.slice(0, this.cursorPosition) +
          event.Value +
          notice.slice(this.cursorPosition);
        this.onSaveContent();
      });
    },

    // 打开模板选择对话框
    onShowTemplate() {
      this.$refs.DfSmsTemplate.onShowDialog({
        applyType: this.remindMember.WxOrSms,
        classCode: this.code,
      });
    },

    // 保存为模板选择框改变事件
    onChangeSaveAs(event) {
      window.localStorage.setItem(`${this.code}TempSaveAs`, event);
    },

    // 发送类型改变事件
    onChangeRadio() {
      this.notice =
        this.remindMember.WxOrSms == 1
          ? this.remindMember.WxTemplate
          : this.remindMember.SmsTemplate;
    },

    // 发送时间改变事件
    onChangeDate(event) {
      let hour = event.getHours(),
        minute = event.getMinutes();
      this.remindMember.SendHour = hour;
      this.remindMember.SendMinute = minute;
    },

    // 保存发送内容
    onSaveContent() {
      if (this.remindMember.WxOrSms == 1) {
        this.remindMember.WxTemplate = this.notice;
      } else {
        this.remindMember.SmsTemplate = this.notice;
      }
    },

    // 模板选中事件
    onChangeTemplate(event) {
      this.selectTemItem = event;
      this.notice = event.TplContent;
      this.onSaveContent();
    },

    // 提醒员工选中
    onChangeRemind(event) {
      this.remindEmployee = event.employee;
      this.remindManager = event.manager;
      console.log(this.remindEmployee, this.remindManager);
    },
  },
};
</script>

<style lang="less" scoped>
.consume-manage {
  .enable-box {
    margin: 20px 0;

    .market-i(20px);
    i:hover {
      color: #ccc !important;
      cursor: unset !important;
    }

    span {
      margin-left: 4px;
      font-size: 12px;
      color: #999;
    }
  }

  .el-form {
    .el-form-item {
      display: table;

      ::v-deep .el-form-item__content {
        // margin-left: 0 !important;
      }

      i {
        margin: 0 3px 0 0;
      }

      .el-switch {
        margin-right: 10px;
      }

      .template-box {
        .el-radio-group {
          margin-right: 20px;
        }
      }

      .notice-box {
        .flex-row();
        align-items: unset;
        width: 60vw;

        .el-textarea {
          width: 50%;
        }

        .text-insert {
          margin-left: 20px;
          font-size: 12px;
          line-height: 20px;
          color: #999;

          .title {
            color: black;
          }

          div > span {
            color: #409eff;
            cursor: pointer;
          }
        }
      }
    }

    .start-img {
      ::v-deep .el-image {
        width: auto;
        height: 300px !important;
      }
    }
  }

  ::v-deep .df-col .el-form-item__content {
    .flex-col();
    align-items: unset;

    .el-image {
      height: 200px;
    }
  }

  i {
    font-size: 16px;
    margin-left: 3px;
    color: #ccc !important;
  }

  .strong {
    ::v-deep label {
      color: black;
      font-weight: bold;
    }

    span {
      color: #999 !important;
    }
  }
}
</style>
